import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">

<path d="M8593 11636 c-11 -36 -11 -38 3 -24 9 8 14 26 12 39 -2 20 -5 18 -15
-15z"/>
<path d="M8566 11581 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
<path d="M8516 11485 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2
0 -6 -7 -10 -15z"/>
<path d="M8430 11360 c-6 -12 -9 -24 -7 -27 3 -2 11 5 17 17 6 12 9 24 7 27
-3 2 -11 -5 -17 -17z"/>
<path d="M8305 11226 c-151 -155 -345 -293 -555 -394 -184 -89 -328 -138 -586
-202 -93 -22 -177 -45 -186 -50 -10 -6 -36 -10 -58 -10 -22 0 -40 -4 -40 -9 0
-5 -12 -12 -27 -14 -16 -2 2 -5 40 -6 57 -1 67 -4 67 -19 0 -9 -9 -23 -20 -30
-16 -9 -20 -23 -20 -61 0 -31 -6 -57 -18 -72 -37 -48 -67 -112 -55 -116 15 -6
-17 -96 -45 -126 -27 -29 -28 -37 -2 -37 28 0 25 -27 -5 -34 -15 -4 -22 -11
-19 -19 9 -25 -14 -67 -37 -67 -12 0 -36 -8 -53 -19 -17 -10 -53 -24 -79 -31
-26 -7 -46 -15 -43 -17 17 -17 562 97 756 158 189 59 447 173 524 232 6 4 25
18 42 30 122 86 244 196 227 203 -7 3 -13 12 -13 19 0 7 -6 16 -12 18 -8 3 -6
6 5 6 17 1 22 16 18 57 -1 12 5 27 14 34 8 7 12 16 9 21 -3 5 2 12 10 15 19 7
21 31 4 36 -8 3 -2 15 15 34 15 16 25 34 23 41 -3 6 6 28 19 47 14 21 20 38
14 42 -6 4 -4 15 5 30 9 13 16 37 16 53 0 16 4 32 9 36 6 3 8 14 6 25 -2 12 6
27 22 40 24 19 25 23 13 45 -7 14 -10 28 -7 32 4 3 7 1 7 -5 0 -7 7 -12 16
-12 12 0 15 6 10 24 -3 14 -1 30 6 38 7 8 18 21 23 30 6 10 23 35 39 58 16 22
27 42 25 44 -2 3 -49 -42 -104 -98z m-1265 -688 c0 -17 -3 -28 -7 -24 -11 10
-11 56 -1 56 4 0 8 -14 8 -32z m107 26 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11
1 17 -2 13 -5z m930 -42 c8 -5 9 -12 2 -22 -8 -13 -10 -13 -19 0 -18 27 -9 39
17 22z m-27 -46 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0
10 -2 10 -4z m-1185 -446 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10 3 6 8 10 10
10 2 0 7 -4 10 -10z m72 -36 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2
13 -5z"/>
<path d="M8300 11046 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M9065 10798 c-53 -117 -100 -182 -220 -308 -82 -86 -120 -117 -245
-202 -243 -164 -590 -299 -975 -379 -27 -5 -68 -14 -90 -19 -62 -13 -271 -44
-415 -60 -286 -33 -474 -61 -675 -100 -185 -37 -468 -113 -542 -146 -17 -8
-36 -14 -41 -14 -6 0 -14 -5 -18 -12 -5 -8 4 -9 32 -5 21 3 48 2 59 -3 18 -7
19 -8 3 -15 -10 -4 -18 -16 -18 -26 0 -10 -5 -19 -10 -19 -6 0 -23 -12 -37
-26 -20 -20 -28 -23 -34 -12 -7 10 -9 10 -9 0 0 -7 6 -15 14 -18 8 -3 20 -19
27 -36 11 -27 10 -32 -13 -52 -13 -13 -34 -29 -46 -35 -16 -9 -21 -18 -16 -35
3 -12 -4 -50 -15 -84 -12 -34 -21 -69 -21 -77 0 -9 -6 -22 -13 -30 -8 -7 -22
-38 -31 -69 -10 -31 -22 -56 -27 -56 -5 0 -9 -9 -9 -19 0 -10 -7 -22 -16 -25
-11 -4 -14 -14 -9 -34 4 -21 1 -33 -15 -47 -15 -13 -19 -26 -15 -44 5 -24 -1
-29 -82 -66 -48 -22 -94 -41 -103 -42 -8 -1 -26 -5 -40 -9 -24 -7 -24 -7 -7
13 9 11 17 29 17 42 0 12 7 24 16 28 9 3 14 13 11 24 -3 10 -2 19 2 21 13 6
42 68 36 78 -3 5 1 20 9 32 9 12 16 33 16 45 0 13 13 48 29 78 16 31 35 80 41
110 6 30 16 55 21 55 5 0 9 10 9 23 0 13 6 29 12 35 10 10 10 14 0 21 -9 5 -6
14 13 31 14 13 25 34 25 47 0 13 7 37 16 54 8 16 12 39 9 49 -4 12 -2 20 4 20
6 0 11 -4 11 -10 0 -5 7 -10 15 -10 18 0 20 20 3 20 -8 1 -6 6 4 14 10 7 15
15 12 18 -10 11 -116 -25 -264 -89 -34 -15 -214 -110 -255 -136 -46 -28 -141
-93 -153 -104 -7 -7 -17 -13 -20 -13 -4 0 -18 -10 -32 -22 -14 -13 -48 -41
-75 -62 -51 -40 -209 -196 -255 -251 -14 -16 -54 -68 -89 -114 -35 -46 -84
-106 -108 -135 -25 -28 -57 -66 -71 -84 -15 -19 -40 -48 -57 -65 -16 -18 -43
-50 -60 -72 -16 -22 -38 -49 -48 -60 -45 -48 -223 -336 -288 -465 -26 -52 -59
-133 -59 -144 0 -8 -4 -16 -8 -18 -8 -3 -82 -200 -115 -303 -18 -60 -40 -151
-41 -179 -1 -11 -11 -26 -24 -32 -20 -10 -21 -12 -7 -18 11 -4 15 -12 11 -22
-4 -9 0 -27 8 -39 8 -12 14 -35 14 -51 -1 -33 22 -104 34 -104 10 0 22 66 15
84 -7 18 18 42 28 27 3 -6 8 -34 11 -63 3 -34 1 -46 -4 -33 -7 14 -10 3 -15
-44 -10 -89 -9 -101 7 -101 13 0 24 -64 27 -159 1 -20 5 0 10 44 5 45 5 122 0
175 -5 56 -5 96 0 98 5 2 9 15 9 29 0 14 8 32 18 39 15 12 13 13 -20 14 -39 0
-54 22 -23 34 8 3 15 13 15 23 0 13 -9 8 -33 -19 -18 -21 -36 -38 -40 -38 -17
0 -5 43 18 64 13 13 23 25 20 28 -3 2 -1 19 5 36 5 20 5 30 -1 27 -5 -4 -9
-13 -9 -21 0 -8 -7 -14 -15 -14 -8 0 -15 9 -15 20 0 11 5 20 10 20 6 0 10 10
10 23 0 24 31 57 54 57 20 0 27 -27 10 -40 -10 -9 -14 -9 -14 -1 0 6 -4 11
-10 11 -15 0 -12 -14 9 -37 11 -11 21 -38 23 -59 l3 -39 12 39 c11 34 10 41
-4 51 -10 8 -12 14 -6 18 6 4 13 0 17 -8 3 -8 13 -15 21 -15 12 0 15 -6 11
-20 -3 -11 -9 -29 -12 -40 -3 -11 -9 -20 -13 -20 -8 0 -6 -53 4 -77 2 -7 0
-15 -5 -18 -6 -4 -10 -29 -10 -57 0 -56 8 -59 17 -6 3 18 10 42 15 53 8 14 8
20 -1 23 -15 5 -14 79 1 84 6 2 13 31 16 66 4 52 7 62 23 62 11 0 19 7 19 15
0 8 -5 15 -11 15 -5 0 1 19 15 42 29 49 53 65 41 27 -4 -13 -9 -39 -12 -58 -3
-19 -14 -49 -24 -65 -11 -17 -19 -45 -19 -62 0 -17 -5 -44 -10 -60 -6 -16 -14
-58 -19 -94 -5 -36 -17 -110 -27 -165 -20 -119 -24 -511 -6 -640 7 -44 16
-114 22 -155 6 -41 14 -93 20 -115 5 -22 16 -74 25 -115 9 -41 20 -84 25 -95
5 -11 23 -66 40 -122 17 -57 36 -105 41 -109 5 -3 9 -12 9 -21 0 -27 139 -315
173 -358 4 -5 30 -46 58 -90 27 -44 54 -81 59 -83 6 -2 10 -8 10 -14 0 -6 19
-32 41 -60 47 -55 84 -63 84 -18 0 18 8 29 30 38 28 12 29 14 13 26 -24 17
-23 63 1 77 18 12 18 49 0 49 -5 0 -9 5 -9 11 0 6 -24 52 -54 102 -255 435
-378 914 -362 1404 12 364 63 610 194 928 59 145 205 399 267 465 18 19 35 47
37 61 4 24 3 25 -19 15 -12 -6 -23 -14 -23 -17 0 -4 -34 -32 -75 -63 -41 -31
-75 -54 -75 -51 0 17 271 264 344 314 12 8 23 18 26 21 10 13 179 121 256 165
71 39 196 102 339 170 49 23 260 104 340 130 134 43 208 65 224 65 9 0 41 7
69 15 45 14 52 19 52 42 0 25 -1 26 -50 20 -29 -4 -50 -2 -50 3 0 6 3 10 8 11
4 0 29 1 57 2 l50 2 -1 -32 c-1 -37 1 -38 81 -22 28 5 79 13 115 18 187 27
232 33 305 41 44 5 161 16 260 24 243 21 533 55 573 67 24 8 40 7 62 -3 44
-19 37 -31 -14 -24 -55 9 -67 -6 -23 -28 24 -12 27 -16 12 -16 -11 0 -26 -9
-33 -20 -7 -12 -8 -20 -2 -20 5 0 10 -7 10 -15 0 -8 -6 -15 -12 -15 -10 0 -10
-3 0 -9 11 -6 12 -26 7 -85 -7 -76 -7 -76 -37 -76 -37 0 -28 -15 14 -24 l33
-7 -25 -15 c-23 -14 -15 -15 90 -10 63 3 158 15 210 26 52 11 117 24 143 30
27 5 69 19 93 29 l44 20 26 -24 c14 -13 44 -32 67 -43 41 -19 182 -121 256
-185 80 -69 283 -281 343 -359 235 -305 382 -603 474 -963 90 -351 100 -767
29 -1145 -70 -367 -262 -775 -509 -1080 -66 -81 -261 -282 -336 -345 -90 -76
-292 -225 -306 -225 -3 0 -25 -14 -49 -30 -63 -43 -288 -154 -387 -191 -284
-105 -474 -146 -781 -170 -261 -21 -573 15 -872 99 -70 20 -91 27 -240 85
-166 64 -479 241 -601 340 -10 8 -48 39 -84 68 -63 51 -121 104 -237 217 -30
29 -58 50 -62 47 -3 -4 2 -12 12 -19 20 -15 23 -42 5 -49 -10 -3 -10 -9 0 -27
9 -14 10 -26 4 -32 -6 -6 -6 -20 1 -38 9 -23 7 -31 -6 -45 -10 -9 -24 -14 -32
-11 -22 8 -18 -5 11 -39 14 -16 29 -35 32 -40 4 -6 21 -21 37 -34 17 -12 59
-49 95 -81 130 -116 148 -130 158 -130 6 0 12 -4 14 -9 4 -12 193 -137 283
-187 139 -78 287 -145 445 -201 143 -51 282 -88 425 -112 33 -6 92 -16 130
-23 112 -21 728 -16 764 6 4 3 13 1 21 -4 21 -13 -52 -27 -150 -29 -46 -1 -89
-6 -95 -11 -5 -4 -27 -11 -47 -15 -28 -5 -38 -12 -38 -26 0 -11 5 -19 12 -19
9 0 9 -3 0 -12 -7 -7 -12 -21 -12 -31 0 -31 -40 -60 -74 -53 -24 5 -27 4 -16
-9 7 -9 29 -15 51 -15 22 0 39 -4 39 -10 0 -5 -6 -10 -14 -10 -8 0 -18 -4 -21
-10 -4 -6 15 -8 54 -3 42 4 61 2 61 -5 0 -29 -226 -46 -615 -47 -310 0 -746
16 -815 30 -14 3 -196 8 -405 10 l-380 6 45 -20 c37 -16 42 -21 27 -26 -12 -5
-17 -15 -14 -26 3 -11 1 -19 -4 -19 -19 0 -47 -86 -54 -162 -5 -56 -11 -78
-21 -78 -16 0 -44 -51 -44 -80 0 -23 -35 -96 -56 -118 -8 -8 -14 -35 -14 -65
0 -53 -33 -143 -48 -133 -4 2 -6 -11 -4 -30 3 -27 -1 -40 -18 -56 -16 -15 -21
-29 -18 -49 4 -25 1 -29 -21 -30 -24 0 -23 -2 9 -14 32 -12 33 -14 10 -16 -25
-2 -45 -4 -65 -8 -5 -1 -4 -5 4 -10 22 -14 22 -41 1 -41 -28 0 -33 -18 -8 -26
22 -7 22 -7 3 -15 -11 -4 -50 -8 -86 -8 -36 -1 -90 -5 -120 -10 -30 -5 -114
-12 -186 -15 -112 -4 -133 -3 -133 9 0 8 5 15 11 15 6 0 17 6 25 14 7 8 19 17
26 19 9 4 8 10 -6 21 -19 15 -18 16 7 42 15 15 32 45 38 68 15 58 42 130 51
136 4 3 14 35 23 70 27 114 47 168 64 173 12 3 17 16 18 45 2 48 47 182 65
193 14 9 13 10 -17 28 -17 10 -13 11 22 6 l43 -7 -11 31 c-6 17 -15 29 -20 26
-12 -8 -59 5 -59 16 0 5 6 9 14 9 26 0 76 35 76 52 0 9 -8 21 -18 27 -14 8
-15 12 -4 22 6 7 12 17 12 21 0 12 -98 10 -106 -3 -3 -6 -15 -9 -25 -6 -10 3
-58 -1 -106 -8 -174 -26 -212 -34 -413 -89 l-55 -15 25 19 c14 11 35 20 48 20
13 0 20 4 17 10 -7 11 74 52 87 44 5 -3 7 0 6 6 -2 7 15 19 37 26 22 8 45 21
51 29 5 7 22 17 37 20 15 4 27 11 27 16 0 5 7 9 15 9 8 0 15 5 15 10 0 6 -9
10 -20 10 -11 0 -20 -4 -20 -8 0 -5 -25 -19 -55 -31 -30 -13 -71 -34 -90 -47
-20 -13 -40 -24 -45 -24 -5 0 -41 -23 -82 -50 -136 -95 -176 -120 -265 -166
-108 -57 -218 -130 -298 -197 -142 -119 -298 -307 -375 -452 -24 -44 -46 -84
-50 -90 -13 -18 -82 -197 -101 -264 -11 -36 -29 -109 -40 -161 -47 -218 -81
-377 -89 -415 -8 -39 -43 -197 -60 -265 -5 -19 -11 -46 -14 -60 -4 -14 2 -8
13 15 11 22 20 46 20 53 2 22 96 194 144 262 75 107 250 290 278 290 14 0 15
-5 3 -52 -8 -29 -14 -66 -14 -83 0 -17 -14 -91 -30 -165 -45 -198 -71 -332
-71 -359 1 -13 -3 -29 -8 -35 -11 -14 -45 -155 -39 -162 3 -2 11 10 18 28 66
177 233 395 416 544 36 29 38 32 21 48 -9 10 -22 14 -28 10 -8 -4 -7 -9 2 -15
11 -8 10 -12 -5 -21 -11 -6 -30 -22 -42 -35 -24 -26 -40 -29 -47 -10 -4 9 -8
8 -16 -3 -8 -11 -11 -12 -11 -2 0 10 -3 10 -15 -2 -21 -21 -19 -23 15 -18 33
5 42 -13 11 -23 -10 -3 -36 -22 -56 -41 -29 -27 -40 -32 -46 -22 -14 22 -10
38 10 38 32 0 34 18 6 46 -15 15 -26 36 -26 48 1 11 1 33 2 48 0 29 12 37 25
16 4 -7 3 -8 -4 -4 -7 4 -12 3 -12 -3 0 -5 5 -13 10 -16 6 -3 10 -22 10 -41 0
-38 22 -47 40 -15 12 23 13 34 2 28 -4 -3 -8 11 -10 30 -1 21 2 32 8 28 7 -4
9 7 6 30 -5 40 19 105 39 105 7 0 12 6 12 14 0 20 -34 8 -64 -25 -15 -15 -31
-25 -36 -22 -4 3 -11 -4 -14 -16 -3 -11 -9 -18 -14 -15 -13 8 -11 44 2 44 6 0
29 16 52 35 22 19 46 35 53 35 7 0 17 6 21 14 13 24 267 156 393 205 47 18 92
36 100 40 8 5 76 23 150 40 74 18 151 37 170 42 34 9 149 26 370 55 59 8 249
17 435 20 322 6 593 -3 1005 -36 116 -9 164 -12 420 -26 285 -15 456 -20 460
-15 3 3 34 7 70 7 l65 2 -60 -13 c-33 -8 -76 -16 -96 -20 -27 -4 -36 -10 -35
-23 1 -12 -8 -18 -28 -20 -16 -2 -38 -12 -50 -23 -11 -11 -22 -15 -24 -9 -6
18 -20 10 -24 -15 -4 -17 0 -29 11 -35 12 -7 5 -9 -26 -10 -44 0 -61 -19 -20
-22 181 -12 746 60 952 122 17 5 81 23 142 40 121 34 156 46 304 108 95 39
151 67 258 131 59 35 173 111 181 121 3 3 25 21 49 40 68 52 297 270 285 270
-3 0 2 7 10 16 22 22 20 41 -2 21 -10 -10 -24 -17 -31 -17 -8 0 -11 -6 -7 -15
3 -9 0 -15 -8 -15 -8 0 -20 -10 -29 -23 -8 -12 -18 -19 -23 -14 -4 4 -3 12 4
19 6 6 8 24 5 39 -4 21 -1 30 11 35 9 3 16 12 16 19 0 7 4 15 9 17 4 1 12 14
16 27 l7 24 -28 -27 c-33 -30 -49 -34 -35 -8 14 23 48 62 55 62 7 0 85 84 128
137 18 23 66 82 106 132 94 116 178 235 247 346 52 83 225 429 225 449 0 8 4
17 9 20 5 3 12 17 15 31 4 14 19 54 35 88 27 60 118 322 139 402 34 125 52
209 52 233 0 16 4 32 10 38 5 5 14 37 19 69 6 33 15 85 21 115 6 30 12 71 15
90 2 19 7 46 10 60 16 76 47 261 51 300 3 25 9 72 15 105 15 86 48 401 49 457
0 25 3 48 8 50 21 9 44 79 39 115 -7 40 4 70 21 59 11 -7 22 15 22 45 0 10 4
19 10 19 5 0 7 6 4 14 -3 8 -12 12 -20 9 -9 -3 -14 1 -14 12 0 9 4 14 9 11 17
-11 60 35 67 72 4 20 10 39 13 42 13 11 31 51 31 69 0 27 17 36 24 13 4 -10 5
-40 5 -65 -1 -26 1 -50 4 -54 4 -3 7 1 7 11 0 9 5 14 10 11 6 -3 10 -1 10 4 0
6 -4 11 -8 11 -5 0 -7 48 -4 108 10 255 -77 683 -198 967 -162 381 -355 677
-618 950 -54 55 -133 132 -177 171 -180 161 -192 175 -172 187 163 102 367
292 536 499 69 85 86 116 57 105 -20 -8 5 60 30 77 26 19 12 31 -18 14 -12 -7
-25 -16 -28 -19 -3 -3 -40 -27 -82 -52 -127 -77 -355 -183 -478 -222 -172 -55
-170 -54 -245 -64 -39 -6 -108 -17 -155 -25 -137 -26 -202 -19 -127 13 366
157 683 434 829 724 57 112 80 180 124 362 19 77 38 156 43 175 29 106 73 310
69 320 -2 7 -17 -17 -33 -52z m31 -1253 c-3 -9 -11 -13 -16 -10 -8 5 -7 11 1
21 14 18 24 11 15 -11z m-961 -395 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3
6 11 10 16 10 6 0 7 -4 4 -10z m-135 -54 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1
-10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-96 -41 c-10 -8 -26 -14 -34 -14 -9 0
-4 6 10 14 33 19 49 19 24 0z m-64 -15 c0 -5 -8 -10 -17 -10 -15 0 -16 2 -3
10 19 12 20 12 20 0z m-80 -30 c0 -5 -12 -10 -27 -9 -25 0 -26 1 -8 9 27 12
35 12 35 0z m-80 -20 c0 -5 -3 -10 -8 -10 -4 0 -38 -6 -75 -14 -38 -8 -76 -12
-85 -8 -12 5 -4 9 23 14 22 4 58 11 80 16 55 13 65 14 65 2z m-1710 -179 c0
-5 -7 -11 -15 -15 -15 -5 -20 5 -8 17 9 10 23 9 23 -2z m2640 -17 c0 -16 -16
-19 -25 -4 -8 13 4 32 16 25 5 -4 9 -13 9 -21z m-2760 -18 c0 -3 -4 -8 -10
-11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m2688 -7 c-2 -6 -27
-21 -56 -33 -48 -21 -51 -23 -35 -39 27 -27 7 -31 -27 -6 l-31 24 27 9 c14 4
45 19 68 32 45 26 60 30 54 13z m-371 -15 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6
11 1 17 -2 13 -5z m408 -24 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4
10 3 0 8 -4 11 -10z m119 1 c3 -5 -1 -11 -9 -15 -9 -3 -15 0 -15 9 0 16 16 20
24 6z m-754 -25 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0
10 -2 10 -4z m555 -6 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5
0 13 -4 16 -10z m22 -56 c-3 -3 6 -17 20 -30 36 -34 23 -41 -16 -9 -34 30 -39
45 -14 45 9 0 14 -3 10 -6z m-993 -13 c4 -5 -3 -7 -14 -4 -23 6 -26 13 -6 13
8 0 17 -4 20 -9z m1096 -81 c0 -9 9 -20 20 -23 11 -3 20 -12 20 -21 0 -24 -8
-20 -55 23 l-41 38 28 1 c21 0 28 -4 28 -18z m80 -85 c7 -8 16 -13 20 -10 4 3
13 -1 19 -9 9 -11 8 -15 -5 -20 -17 -6 -64 28 -64 46 0 13 16 9 30 -7z m83
-91 c26 -35 18 -36 -18 -2 -19 18 -24 28 -15 28 8 0 23 -12 33 -26z m-4459
-28 c-3 -14 -10 -23 -15 -21 -4 3 -3 17 3 30 14 31 21 26 12 -9z m4520 -15 c3
-5 -1 -11 -9 -15 -15 -6 -29 7 -18 18 9 9 21 8 27 -3z m-4549 -39 c0 -18 -20
-15 -23 4 -3 10 1 15 10 12 7 -3 13 -10 13 -16z m-31 -28 c23 -9 20 -37 -9
-68 -13 -14 -22 -32 -19 -40 3 -8 1 -17 -5 -21 -6 -3 -11 -16 -11 -28 0 -12
-11 -41 -25 -64 -13 -24 -19 -43 -14 -43 6 0 6 -7 -1 -19 -5 -11 -10 -28 -10
-39 0 -11 -11 -36 -25 -56 -15 -22 -25 -51 -25 -74 -1 -20 -8 -64 -16 -97 -9
-33 -18 -78 -21 -100 -3 -22 -9 -44 -14 -49 -5 -6 -9 -18 -9 -28 0 -10 -4 -18
-9 -18 -5 0 -7 -9 -4 -20 4 -14 -7 -32 -41 -63 -25 -24 -46 -51 -46 -59 0 -9
-11 -18 -25 -20 -18 -4 -23 -2 -16 6 4 6 11 23 15 39 8 35 36 68 50 60 6 -4 3
3 -8 15 -10 12 -22 20 -26 19 -7 -2 -60 49 -60 59 0 2 6 4 14 4 19 0 28 43 16
76 -12 30 -4 68 12 58 6 -4 5 -10 -3 -15 -11 -7 -10 -9 4 -9 10 0 22 -12 28
-27 9 -23 7 -35 -10 -68 -12 -22 -21 -43 -21 -47 0 -14 23 -8 36 10 12 15 15
15 24 2 6 -8 9 -25 7 -38 -3 -12 -1 -25 3 -27 11 -7 21 38 10 49 -5 5 1 17 16
28 24 20 33 48 14 48 -5 0 -10 16 -10 36 0 19 -5 44 -10 54 -9 17 -6 20 23 25
31 6 35 11 45 54 14 62 5 129 -20 142 -29 15 -30 7 -8 -36 26 -51 25 -63 -8
-115 -2 -2 -7 2 -12 10 -11 17 -2 62 14 72 6 4 4 9 -7 14 -25 9 -30 0 -31 -48
-1 -23 -6 -43 -13 -45 -10 -3 -12 9 -10 48 2 29 9 55 15 57 6 2 16 21 22 43
11 38 30 54 30 24 0 -8 9 -15 19 -15 10 0 22 -8 25 -17 6 -15 8 -15 16 -3 7
11 4 20 -10 31 -27 23 -35 62 -16 78 9 8 16 19 16 27 0 22 21 27 34 8 13 -17
14 -16 25 3 6 12 8 35 5 52 -5 24 -3 31 10 31 12 0 16 10 16 40 0 33 4 40 19
40 10 0 21 7 25 15 3 8 2 14 -2 14 -18 -3 -22 2 -10 16 16 17 13 16 32 9z
m4659 -49 c14 -19 15 -25 4 -25 -8 0 -19 11 -25 25 -15 32 -2 32 21 0z m78
-127 c11 -21 11 -22 -4 -9 -10 7 -17 17 -17 22 0 15 9 10 21 -13z m-4844 -4
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-257 -469 c0 -8 -4
-15 -10 -15 -5 0 -7 7 -4 15 4 8 8 15 10 15 2 0 4 -7 4 -15z m110 -40 c0 -18
-4 -23 -15 -19 -17 7 -17 7 -9 28 9 24 24 19 24 -9z m-154 -210 c3 -19 12 -36
20 -39 19 -7 18 -46 -1 -46 -8 0 -15 8 -15 19 0 10 -9 25 -19 32 -11 8 -22 28
-26 46 -6 28 -4 31 14 26 14 -3 23 -17 27 -38z m-36 -50 c0 -8 -7 -15 -15 -15
-16 0 -20 12 -8 23 11 12 23 8 23 -8z m-62 -190 c-2 -10 -7 -12 -17 -5 -13 7
-13 11 -1 25 15 18 24 8 18 -20z m5929 -422 c-3 -10 -5 -4 -5 12 0 17 2 24 5
18 2 -7 2 -21 0 -30z m-204 -491 c-3 -12 -9 -22 -14 -22 -11 0 -12 31 -3 55
10 25 25 -5 17 -33z m-98 -429 c-9 -53 -27 -138 -40 -189 -29 -109 -114 -365
-126 -378 -5 -6 -9 -16 -9 -24 0 -27 -95 -223 -132 -271 -10 -13 -18 -28 -18
-33 0 -5 -17 -32 -38 -61 -21 -29 -44 -67 -51 -84 -8 -18 -17 -33 -21 -33 -4
0 -12 -11 -18 -25 -6 -14 -18 -25 -27 -25 -8 0 -15 -6 -15 -14 0 -20 -36 -61
-43 -49 -3 5 2 19 11 29 31 33 33 53 10 76 -21 21 -21 22 -2 30 11 5 26 23 33
41 17 41 62 110 78 120 7 4 13 15 13 22 0 8 4 15 9 15 5 0 20 21 33 48 13 26
26 48 30 50 3 2 23 38 43 80 20 42 41 84 46 92 5 8 12 22 15 30 3 8 17 38 30
65 39 82 122 330 165 490 18 65 34 114 37 107 2 -6 -4 -55 -13 -109z m-4547
-1150 c-3 -5 -11 -23 -18 -40 -8 -18 -20 -33 -26 -33 -9 0 -9 6 -1 25 7 14 15
25 20 25 4 0 7 7 7 15 0 8 5 15 11 15 6 0 9 -3 7 -7z m3980 -44 c15 -18 21
-36 17 -47 -6 -16 -8 -16 -21 2 -8 11 -14 28 -14 38 0 11 -7 18 -20 18 -11 0
-20 5 -20 10 0 21 36 8 58 -21z m-83 -29 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4
10 3 6 11 10 16 10 6 0 7 -4 4 -10z m53 -42 c7 -7 12 -19 11 -27 0 -11 -2 -12
-6 -3 -2 6 -11 12 -19 12 -8 0 -14 7 -14 15 0 18 12 19 28 3z m-102 -154 c-5
-14 -9 -15 -19 -5 -9 9 -9 14 3 21 18 11 24 6 16 -16z m-1039 -520 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-81 -33 c-7 -11 -105 -35 -113
-28 -3 3 17 12 43 21 58 17 77 19 70 7z m-136 -41 c-8 -6 -24 -9 -35 -9 -15 0
-13 4 10 14 32 14 49 10 25 -5z m-65 -19 c-12 -12 -113 -27 -102 -15 4 4 29
11 55 15 26 3 49 7 52 8 2 0 0 -3 -5 -8z m-127 -36 c12 -12 -8 -15 -47 -9 -30
5 -39 10 -29 16 12 8 66 3 76 -7z m262 -125 c-20 -13 -40 -13 -40 0 0 6 12 10
28 10 21 0 24 -2 12 -10z m-80 -20 c-20 -13 -33 -13 -25 0 3 6 14 10 23 10 15
0 15 -2 2 -10z m-50 -20 c-8 -5 -22 -9 -30 -9 -10 0 -8 3 5 9 27 12 43 12 25
0z m-135 -45 c-59 -17 -93 -19 -79 -6 11 11 46 18 94 19 32 1 31 -1 -15 -13z
m-162 -32 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-63 -13
c-20 -13 -50 -13 -50 0 0 6 15 10 33 10 24 0 28 -3 17 -10z m-2836 -101 c-6
-11 -31 -12 -37 -1 -3 4 6 8 19 8 13 1 21 -2 18 -7z m4046 -439 c0 -5 -5 -10
-11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m24 -109 c3 -5 -3
-11 -15 -14 -16 -4 -18 -3 -8 9 13 16 16 17 23 5z m-1348 -530 c-3 -5 -15 -7
-26 -4 -28 7 -25 13 6 13 14 0 23 -4 20 -9z m-96 -9 c0 -12 -77 -30 -90 -22
-9 5 -9 9 -1 14 6 4 11 2 11 -4 0 -7 10 -5 28 4 30 17 52 20 52 8z m-167 -29
c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m120 -80 c-7 -2 -19
-2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-3946 -395 c-3 -8 -6 -5 -6 6 -1
11 2 17 5 13 3 -3 4 -12 1 -19z m-83 -183 c-4 -8 -8 -15 -10 -15 -2 0 -4 7 -4
15 0 8 4 15 10 15 5 0 7 -7 4 -15z m44 -128 c-4 -27 -28 -35 -28 -8 0 28 -20
37 -21 9 0 -13 -3 -17 -6 -10 -10 24 4 40 32 34 19 -3 25 -10 23 -25z m-38
-46 c0 -9 -24 -21 -41 -21 -5 0 -9 7 -9 15 0 9 9 15 25 15 14 0 25 -4 25 -9z"/>
<path d="M4115 8010 c-7 -12 2 -30 16 -30 5 0 9 9 9 20 0 21 -15 27 -25 10z"/>
<path d="M8953 4820 c-6 -17 -8 -32 -3 -35 4 -2 10 9 14 27 8 41 3 45 -11 8z"/>
<path d="M8900 4725 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M6720 10054 c0 -8 6 -14 14 -14 7 0 19 6 25 14 9 11 6 14 -14 14 -15
0 -25 -6 -25 -14z"/>
<path d="M5025 9890 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M4918 9814 c-16 -8 -28 -19 -28 -24 0 -16 10 -12 42 15 33 28 27 32
-14 9z"/>
<path d="M4824 9747 c-11 -12 -47 -44 -79 -72 -125 -107 -290 -279 -369 -385
-22 -30 -43 -57 -46 -60 -4 -3 -21 -29 -40 -57 -18 -29 -37 -53 -42 -53 -4 0
-8 -4 -8 -10 0 -5 -17 -36 -37 -67 -44 -68 -48 -76 -140 -253 -39 -74 -83
-166 -98 -205 -15 -38 -32 -75 -37 -81 -5 -7 -15 -34 -22 -60 -8 -27 -26 -80
-40 -119 -33 -88 -110 -385 -125 -485 -7 -41 -16 -91 -21 -110 -5 -19 -12 -57
-15 -85 -4 -27 -11 -66 -16 -85 -8 -34 -36 -276 -64 -565 -15 -150 -17 -333
-5 -430 9 -77 11 -48 10 153 0 136 6 213 23 306 1 6 5 13 8 16 3 3 10 102 16
220 6 118 16 229 21 245 6 17 21 64 33 105 40 137 113 331 155 412 8 14 14 33
14 42 0 9 3 16 8 16 4 0 20 26 36 58 42 81 118 216 125 222 3 3 15 22 26 43
11 22 30 50 43 63 12 14 22 27 22 30 0 9 77 118 109 154 17 19 34 40 38 45 59
75 75 97 71 101 -3 2 -19 -9 -35 -25 -24 -22 -34 -26 -43 -18 -9 9 -16 7 -30
-10 -24 -31 -36 -29 -52 7 -7 17 -17 30 -21 30 -5 0 -15 6 -23 14 -15 15 -20
86 -6 86 5 0 17 16 28 35 21 39 39 45 47 15 3 -11 12 -20 20 -20 19 0 35 42
18 48 -15 5 -14 22 2 22 7 0 19 -9 27 -19 7 -11 18 -18 22 -15 5 3 0 14 -12
24 -11 9 -20 24 -20 33 0 22 -21 67 -32 67 -4 0 -8 7 -8 15 0 20 6 19 31 -6
11 -11 18 -27 15 -34 -3 -8 0 -17 6 -21 15 -9 2 74 -15 95 -10 12 -9 14 4 9
11 -4 15 0 15 14 -1 15 8 24 29 31 16 6 37 20 47 31 10 12 17 15 18 9 0 -15
30 -18 30 -3 0 6 -4 10 -10 10 -5 0 -10 12 -10 26 0 21 4 24 20 19 12 -4 20
-2 20 5 0 7 -9 14 -20 17 -22 6 -27 28 -9 39 7 4 16 -1 21 -10 5 -9 13 -14 19
-11 13 9 11 53 -3 57 -6 2 5 14 25 27 20 12 37 29 37 36 0 7 10 22 23 32 12
10 37 37 55 61 19 23 40 42 48 42 8 0 14 4 14 8 0 8 119 132 127 132 8 0 73
55 73 63 0 15 -26 6 -46 -16z m-441 -522 c-25 -19 -30 1 -7 27 17 18 19 18 22
3 2 -9 -5 -23 -15 -30z m-126 -202 c-11 -10 -26 8 -20 23 5 15 7 14 15 -1 6
-9 7 -19 5 -22z m-33 -38 c-4 -8 -1 -15 7 -15 7 0 9 -3 6 -6 -8 -8 -37 14 -37
27 0 5 7 9 15 9 9 0 12 -6 9 -15z m-84 -205 c0 -5 -5 -10 -11 -10 -5 0 -7 5
-4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m35 -9 c3 -5 1 -12 -5 -16 -5 -3 -10 1
-10 9 0 18 6 21 15 7z m-55 -103 c0 -26 5 -50 10 -53 20 -12 9 -23 -26 -27
-34 -4 -56 8 -37 21 5 3 15 25 23 48 12 39 11 45 -5 63 -17 19 -17 19 9 7 22
-10 26 -18 26 -59z m80 62 c0 -5 -4 -10 -10 -10 -10 0 -33 -71 -25 -78 10 -10
35 22 35 44 0 28 8 30 25 7 13 -18 35 -10 35 13 0 8 9 14 20 14 11 0 20 -3 20
-8 0 -11 -57 -62 -69 -62 -6 0 -10 -3 -9 -7 3 -10 -34 -53 -45 -53 -5 0 -7 7
-3 15 3 8 -3 20 -12 27 -22 17 -28 88 -7 88 8 0 15 5 15 10 0 6 7 10 15 10 8
0 15 -4 15 -10z m-65 -206 c-8 -20 -15 -38 -15 -40 0 -2 -9 -4 -19 -4 -38 0 2
68 47 79 1 1 -5 -15 -13 -35z m-168 -36 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3
-3 4 -12 1 -19z m13 -43 c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8 23 11 12 23 8
23 -8z m60 -1 c0 -9 -7 -22 -17 -29 -11 -8 -17 -29 -18 -69 -1 -31 -6 -60 -10
-64 -4 -4 -5 3 -2 16 3 12 2 22 -1 22 -4 0 -7 21 -7 47 0 33 6 54 20 70 24 27
35 29 35 7z m47 -6 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-39
-53 c-10 -23 -28 -34 -28 -18 0 7 31 43 36 43 2 0 -2 -11 -8 -25z m-108 -17
c0 -5 -4 -8 -10 -8 -5 0 -10 10 -10 23 0 18 2 19 10 7 5 -8 10 -18 10 -22z
m20 -63 c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8 23 11 12 23 8 23 -8z m-50 -30
c0 -8 -4 -15 -10 -15 -5 0 -7 7 -4 15 4 8 8 15 10 15 2 0 4 -7 4 -15z m58 -39
c-7 -8 -15 -12 -17 -11 -5 6 10 25 20 25 5 0 4 -6 -3 -14z m-43 -76 c-3 -5
-13 -10 -20 -10 -8 0 -19 -9 -25 -20 -10 -19 -40 -28 -40 -12 0 13 37 40 62
45 13 2 24 5 26 6 2 0 0 -3 -3 -9z m-110 -190 c-4 -12 -9 -19 -12 -17 -3 3 -2
15 2 27 4 12 9 19 12 17 3 -3 2 -15 -2 -27z"/>
<path d="M9275 9720 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M9240 9701 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"/>
<path d="M9195 9670 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M9220 9644 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M9172 9587 c-23 -25 -28 -37 -13 -37 10 0 45 50 39 56 -3 2 -14 -6
-26 -19z"/>
<path d="M5738 9523 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4580 9450 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4551 9394 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4380 9154 c0 -23 -2 -25 -15 -14 -13 11 -18 10 -30 -6 -28 -37 -10
-45 42 -19 35 18 50 45 31 57 -22 13 -28 9 -28 -18z"/>
<path d="M4440 9130 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4656 9075 c-11 -8 -17 -17 -14 -20 2 -3 13 4 23 15 21 23 18 25 -9
5z"/>
<path d="M4545 8970 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M4489 8937 c-14 -13 -28 -31 -32 -40 -3 -9 -13 -17 -22 -17 -8 0 -15
-4 -15 -10 0 -5 -6 -10 -14 -10 -17 0 -27 -22 -17 -38 4 -7 25 7 56 39 28 28
58 58 68 67 37 35 15 43 -24 9z"/>
<path d="M4401 8914 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7363 8883 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M7350 8830 c0 -5 14 -10 30 -10 17 0 30 5 30 10 0 6 -13 10 -30 10
-16 0 -30 -4 -30 -10z"/>
<path d="M4270 8816 c0 -20 32 -45 37 -28 3 6 9 10 14 6 5 -3 9 2 9 11 0 9 -4
13 -10 10 -5 -3 -10 -1 -10 4 0 6 -9 11 -20 11 -11 0 -20 -6 -20 -14z"/>
<path d="M4222 8789 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>
<path d="M3825 7147 c-4 -10 -5 -21 -1 -24 10 -10 18 4 13 24 -4 17 -4 17 -12
0z"/>
<path d="M3840 6945 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
<path d="M3755 6842 c-10 -50 -23 -143 -31 -219 -6 -52 -4 -63 8 -63 11 0 17
16 21 60 4 32 4 67 0 77 -5 16 -4 16 9 -1 13 -16 16 -17 21 -3 3 9 2 31 -4 49
-5 18 -7 54 -4 79 8 56 -9 74 -20 21z"/>
<path d="M4322 6408 l3 -443 113 -3 112 -3 0 446 0 445 -115 0 -115 0 2 -442z"/>
<path d="M4635 6838 c-3 -7 -4 -206 -3 -443 l3 -430 113 -3 112 -3 0 201 0
201 28 -3 c24 -3 28 -8 34 -53 3 -27 7 -116 7 -197 l1 -148 105 0 105 0 0 183
c0 207 -8 235 -77 263 l-36 16 42 19 c60 27 75 66 69 190 -9 190 -56 219 -350
219 -110 0 -150 -3 -153 -12z m288 -187 c17 -83 -9 -162 -48 -147 -12 4 -15
25 -15 101 l0 96 27 -3 c24 -3 29 -9 36 -47z"/>
<path d="M5240 6405 l0 -445 110 0 110 0 0 185 0 185 70 0 70 0 0 85 0 84 -67
3 -68 3 -3 83 -3 82 81 0 80 0 0 90 0 90 -190 0 -190 0 0 -445z"/>
<path d="M5756 6823 c-3 -16 -17 -113 -31 -218 -46 -339 -64 -469 -74 -540
-17 -115 -27 -105 103 -105 l113 0 7 46 c3 26 6 62 6 80 0 34 0 34 49 34 l48
0 6 -70 c3 -39 9 -75 12 -80 10 -15 225 -13 225 3 0 22 -114 792 -126 850 l-6
27 -163 0 -163 0 -6 -27z m192 -341 c18 -209 19 -202 -23 -202 -32 0 -35 3
-35 28 0 52 33 312 40 312 4 0 12 -62 18 -138z"/>
<path d="M6260 6405 l0 -446 91 3 90 3 0 182 c0 171 9 231 25 166 4 -15 28
-100 54 -188 l46 -160 100 0 99 0 0 440 0 440 -92 3 -93 3 0 -186 c0 -102 -4
-185 -8 -185 -5 0 -35 83 -67 185 l-59 185 -93 0 -93 0 0 -445z"/>
<path d="M6880 6405 l0 -448 203 5 c325 8 327 10 326 423 0 245 -10 348 -36
383 -10 15 -41 38 -68 52 -46 23 -62 25 -237 28 l-188 4 0 -447z m290 275 c7
-14 10 -108 8 -281 -3 -246 -4 -261 -23 -275 -45 -33 -46 -26 -46 279 1 158 1
289 1 292 0 15 51 2 60 -15z"/>
<path d="M7560 6839 c0 -6 -9 -73 -20 -148 -11 -75 -35 -251 -55 -391 -19
-140 -37 -274 -40 -297 l-6 -43 115 0 c63 0 117 4 120 8 3 5 6 41 7 80 l2 72
47 0 47 0 9 -77 9 -78 113 -3 112 -3 0 27 c0 14 -18 146 -40 293 -23 146 -51
334 -63 416 l-22 150 -168 3 c-120 2 -167 -1 -167 -9z m201 -495 l4 -59 -39
-3 -38 -3 6 68 c9 89 25 219 33 263 5 26 10 5 19 -86 6 -67 13 -148 15 -180z"/>
<path d="M8020 6760 l0 -90 70 0 70 0 0 -355 0 -356 113 3 112 3 3 352 2 352
63 3 62 3 3 88 3 87 -251 0 -250 0 0 -90z"/>
<path d="M8645 6798 c-7 -57 -13 -102 -75 -541 -22 -158 -40 -290 -40 -293 0
-2 53 -3 118 -2 l117 3 3 35 c1 19 5 54 8 78 l5 42 43 0 43 0 7 -46 c3 -26 6
-62 6 -81 l0 -34 118 3 c110 3 117 4 115 23 -2 11 -21 142 -43 290 -22 149
-50 339 -62 423 l-22 152 -167 0 -167 0 -7 -52z m199 -343 c8 -88 12 -163 9
-167 -7 -13 -73 -9 -73 5 0 11 39 336 41 347 4 15 11 -41 23 -185z"/>
<path d="M5662 6645 c-11 -12 -10 -15 3 -15 8 0 15 7 15 15 0 18 -2 18 -18 0z"/>
<path d="M7980 6631 c0 -7 22 -11 55 -11 48 0 57 -3 65 -22 8 -22 9 -22 9 5 1
26 -1 27 -48 27 -27 0 -56 3 -65 6 -9 3 -16 1 -16 -5z"/>
<path d="M8463 6623 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M3720 6463 c0 -81 5 -79 14 5 5 41 3 62 -4 62 -6 0 -10 -27 -10 -67z"/>
<path d="M5181 6505 c0 -11 -10 -35 -21 -52 -14 -24 -16 -33 -7 -33 20 0 39
43 34 76 -2 17 -5 20 -6 9z"/>
<path d="M3631 6436 c-1 -34 2 -47 9 -40 7 7 7 24 1 50 l-9 39 -1 -49z"/>
<path d="M3684 6320 c3 -47 1 -80 -5 -80 -5 0 -8 3 -8 8 5 28 -3 72 -11 67
-18 -11 -10 -140 20 -295 5 -25 14 -81 21 -125 7 -44 18 -91 25 -104 7 -14 11
-28 8 -33 -3 -4 -1 -23 5 -41 6 -17 23 -70 37 -117 15 -47 32 -89 37 -95 6 -6
7 -3 4 10 -24 86 -37 150 -33 161 3 8 0 11 -10 7 -15 -5 -19 15 -15 68 1 15
-4 33 -12 40 -7 8 -17 34 -20 59 -4 25 -11 76 -17 114 -6 38 -15 75 -21 83 -8
9 -8 17 -1 26 6 6 11 68 12 137 3 170 2 190 -10 190 -7 0 -9 -28 -6 -80z"/>
<path d="M5517 6283 c-3 -5 16 -7 41 -5 80 5 90 9 25 11 -34 0 -64 -2 -66 -6z"/>
<path d="M9521 6154 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7730 5963 c0 -13 5 -23 10 -23 13 0 13 11 0 30 -8 12 -10 11 -10 -7z"/>
<path d="M8100 5963 c0 -13 5 -23 10 -23 13 0 13 11 0 30 -8 12 -10 11 -10 -7z"/>
<path d="M8820 5973 c0 -6 -3 -18 -6 -26 -3 -9 3 -16 16 -20 18 -4 20 -2 14
16 -8 26 -24 46 -24 30z"/>
<path d="M8460 5952 c0 -5 7 -13 16 -16 10 -4 14 -1 12 6 -5 15 -28 23 -28 10z"/>
<path d="M3820 5470 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3861 5364 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4580 4970 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4710 4810 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4710 4650 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4705 4610 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M4066 3602 c-3 -5 8 -6 25 -4 16 2 29 6 29 8 0 8 -49 4 -54 -4z"/>
<path d="M3922 3556 c-35 -20 -37 -26 -8 -26 16 0 25 4 21 10 -3 6 1 10 9 10
8 0 18 5 21 10 8 13 -15 11 -43 -4z"/>
<path d="M6728 3443 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4237 3428 c-2 -7 -1 -22 4 -33 6 -16 8 -14 8 13 1 33 -4 41 -12 20z"/>
<path d="M8422 2982 c-12 -19 -21 -40 -18 -46 2 -6 5 -3 5 7 1 9 4 17 9 17 11
1 43 48 35 53 -5 3 -18 -11 -31 -31z"/>
<path d="M8173 2406 c-54 -24 -65 -54 -71 -180 -9 -220 14 -281 113 -292 69
-8 121 14 140 60 19 46 21 319 2 355 -30 58 -120 86 -184 57z m77 -231 c0
-149 -2 -175 -15 -175 -13 0 -15 25 -15 168 0 93 3 172 7 175 19 20 23 -8 23
-168z"/>
<path d="M8503 2406 c-50 -23 -70 -66 -65 -143 1 -12 9 -29 19 -38 15 -16 15
-19 -2 -47 -21 -37 -25 -140 -6 -186 23 -55 112 -78 185 -48 53 22 66 49 66
138 0 65 -3 81 -20 98 -25 25 -25 32 1 48 19 12 21 21 17 73 -6 80 -33 108
-109 115 -35 3 -65 -1 -86 -10z m77 -117 c0 -44 -3 -60 -12 -57 -13 5 -23 80
-14 104 13 34 26 10 26 -47z m0 -210 c0 -60 -3 -80 -12 -77 -8 3 -14 28 -16
68 -3 67 1 90 18 90 6 0 10 -32 10 -81z"/>
<path d="M8830 2403 c-19 -10 -40 -29 -46 -42 -17 -33 -28 -206 -19 -293 6
-60 12 -77 35 -99 51 -52 152 -50 202 3 l28 30 0 162 c0 108 -4 169 -13 185
-31 61 -122 87 -187 54z m86 -89 c8 -41 0 -301 -9 -310 -3 -3 -10 -3 -16 1
-12 7 -16 327 -4 338 14 14 22 6 29 -29z"/>
<path d="M9154 2410 c-39 -16 -64 -59 -64 -111 l0 -49 39 0 c38 0 38 0 44 45
5 53 10 60 33 51 27 -10 12 -74 -51 -209 -32 -67 -60 -139 -62 -159 l-5 -38
116 0 116 0 0 35 0 35 -50 0 -50 0 51 101 c60 119 73 193 45 248 -26 50 -104
75 -162 51z"/>
<path d="M9464 2406 c-46 -20 -64 -52 -64 -112 0 -38 5 -55 20 -69 19 -17 19
-18 0 -43 -26 -32 -29 -165 -4 -200 26 -38 72 -55 130 -49 93 10 114 37 114
151 0 58 -4 79 -20 98 -19 25 -19 26 0 43 26 23 27 105 3 146 -27 46 -115 63
-179 35z m81 -117 c0 -40 -4 -54 -14 -54 -17 0 -26 54 -16 92 11 44 30 20 30
-38z m-1 -145 c10 -26 7 -132 -5 -139 -17 -11 -23 3 -26 60 -3 80 14 123 31
79z"/>
<path d="M9794 2406 c-17 -8 -39 -24 -48 -37 -22 -33 -21 -110 3 -140 19 -24
19 -25 0 -48 -25 -31 -27 -165 -3 -199 49 -71 193 -70 232 2 25 47 22 157 -5
194 l-21 29 21 22 c28 27 28 94 1 139 -28 49 -114 67 -180 38z m81 -116 c0
-34 -5 -55 -12 -57 -10 -4 -13 12 -13 57 0 45 3 61 13 58 7 -3 12 -24 12 -58z
m0 -210 c0 -50 -4 -75 -12 -77 -10 -4 -13 16 -13 77 0 61 3 81 13 78 8 -3 12
-28 12 -78z"/>
<path d="M10434 2401 c-34 -21 -54 -65 -54 -117 0 -33 1 -34 44 -34 l45 0 3
48 c2 34 7 47 18 47 22 0 24 -51 4 -104 -10 -25 -39 -92 -66 -148 -26 -57 -48
-114 -48 -128 l0 -25 121 0 120 0 -3 33 c-3 31 -5 32 -50 35 -27 2 -48 5 -48
8 0 2 20 45 45 95 77 152 80 239 11 284 -41 27 -105 30 -142 6z"/>
<path d="M10752 2405 c-37 -16 -62 -64 -62 -120 0 -35 0 -35 44 -35 l45 0 3
48 c2 34 7 47 18 47 11 0 15 -11 15 -45 -1 -33 -17 -79 -63 -179 -34 -74 -62
-144 -62 -157 0 -24 1 -24 120 -24 l120 0 0 35 0 35 -50 0 c-27 0 -50 2 -50 5
0 2 20 44 44 92 76 149 82 226 23 281 -30 29 -101 37 -145 17z"/>
<path d="M11064 2414 c-37 -8 -57 -41 -62 -99 l-4 -55 51 0 51 0 0 46 c0 38 3
45 18 42 14 -3 17 -14 17 -58 0 -53 -1 -55 -27 -58 -25 -3 -28 -8 -28 -38 0
-29 3 -34 23 -34 37 0 47 -19 47 -92 0 -66 -1 -68 -25 -68 -24 0 -25 2 -25 65
l0 65 -51 0 -52 0 5 -70 c6 -80 20 -103 75 -121 43 -14 114 -7 147 15 52 34
61 183 14 233 -21 22 -21 25 -6 34 41 23 29 145 -17 175 -24 16 -113 26 -151
18z"/>
<path d="M10060 2295 l0 -115 56 0 c48 0 57 3 61 20 3 12 11 19 17 17 7 -2 12
-43 14 -110 2 -89 0 -107 -12 -107 -11 0 -16 15 -18 58 l-3 57 -57 3 -58 3 1
-43 c0 -69 19 -111 59 -130 64 -33 155 -18 186 29 22 34 31 181 15 240 -17 63
-80 92 -128 58 -20 -14 -21 -13 -25 25 l-4 40 78 0 78 0 0 35 0 35 -130 0
-130 0 0 -115z"/>
<path d="M11310 2375 l0 -35 58 0 58 0 -41 -197 -42 -198 55 -3 c30 -2 56 -1
58 1 9 8 84 392 84 428 l0 39 -115 0 -115 0 0 -35z"/>
<path d="M6028 2043 c17 -2 47 -2 65 0 17 2 3 4 -33 4 -36 0 -50 -2 -32 -4z"/>
<path d="M3155 2010 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M3688 1993 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3020 1940 c0 -11 48 -25 57 -16 4 3 -3 6 -15 6 -12 0 -22 5 -22 10
0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z"/>
<path d="M3448 1913 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3047 1903 c-11 -10 -8 -63 3 -63 6 0 10 7 10 15 0 8 5 15 11 15 8 0
9 4 0 13 -6 8 -8 17 -4 20 3 4 2 7 -4 7 -5 0 -13 -3 -16 -7z"/>
<path d="M2931 1866 c-9 -11 -5 -15 20 -20 38 -7 46 -2 29 19 -15 19 -34 19
-49 1z"/>
<path d="M3340 1870 c0 -13 20 -13 40 0 12 8 9 10 -12 10 -16 0 -28 -4 -28
-10z"/>
<path d="M3235 1850 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M3285 1843 c-11 -3 -32 -14 -47 -24 -14 -11 -32 -19 -40 -19 -8 0
-25 -11 -38 -25 -19 -20 -31 -24 -59 -20 -20 2 -28 2 -18 -1 25 -8 21 -21 -10
-36 -15 -7 -29 -21 -31 -31 -3 -14 6 -11 45 15 26 18 50 35 53 38 3 3 16 11
30 18 14 7 39 21 55 32 17 10 48 28 70 39 38 18 34 25 -10 14z"/>
<path d="M2947 1833 c-4 -3 -7 -16 -7 -29 0 -13 -8 -24 -20 -27 -11 -3 -18 -2
-16 2 3 4 1 13 -4 21 -7 11 -11 10 -26 -5 -16 -16 -16 -19 -2 -29 11 -8 14
-21 11 -39 -12 -63 20 -124 44 -84 4 8 16 17 26 20 17 6 17 8 3 23 -9 8 -16
20 -16 25 0 5 -7 9 -15 9 -18 0 -18 -5 -5 -31 9 -16 7 -19 -10 -19 -26 0 -26
29 1 64 12 15 30 26 45 26 13 0 24 4 24 9 0 19 -27 71 -33 64z"/>
<path d="M2960 1741 c0 -5 7 -11 15 -15 8 -3 15 -1 15 3 0 5 -7 11 -15 15 -8
3 -15 1 -15 -3z"/>
<path d="M8865 1423 c-46 -11 -85 -75 -85 -140 0 -20 5 -23 44 -23 l45 0 3 47
c2 37 7 49 22 51 17 4 18 -1 14 -50 -3 -37 -21 -89 -61 -173 -31 -66 -59 -135
-63 -153 l-6 -32 121 0 121 0 0 35 0 35 -51 0 c-28 0 -49 3 -47 8 109 213 126
280 88 340 -30 48 -86 69 -145 55z"/>
<path d="M9580 1421 c-103 -33 -160 -107 -176 -228 -15 -113 59 -234 159 -262
57 -16 141 -10 188 14 37 19 89 64 89 77 0 17 -41 7 -87 -22 -39 -25 -57 -30
-110 -30 -76 0 -120 16 -155 58 -38 45 -51 95 -45 165 13 143 155 228 282 168
l40 -19 -32 -1 c-26 -1 -33 -5 -33 -21 0 -24 -6 -25 -26 -5 -42 42 -113 20
-156 -46 -50 -77 -46 -176 9 -219 30 -24 85 -26 107 -5 8 8 19 15 24 15 6 0
12 -7 16 -15 14 -39 109 -1 146 59 23 39 35 110 26 159 -10 52 -60 120 -103
141 -43 21 -126 29 -163 17z m228 -212 c-3 -49 -9 -76 -24 -96 -23 -31 -64
-44 -64 -20 0 16 27 178 36 217 l6 25 25 -29 c23 -27 25 -36 21 -97z m-142 57
c13 -22 14 -33 4 -83 -17 -79 -34 -103 -76 -103 -39 0 -54 21 -54 74 0 101 84
176 126 112z"/>
<path d="M4797 1413 c-4 -3 -7 -109 -7 -235 l0 -228 60 0 59 0 3 157 c2 114 6
157 16 161 9 3 12 -33 12 -157 l0 -161 60 0 60 0 0 159 c0 88 -4 171 -9 184
-5 13 -24 30 -41 37 -27 11 -36 11 -66 -3 l-34 -17 0 55 0 55 -53 0 c-30 0
-57 -3 -60 -7z"/>
<path d="M6020 1364 c0 -44 -3 -54 -12 -47 -41 32 -110 18 -128 -27 -8 -18
-10 -76 -8 -165 3 -130 4 -138 27 -156 28 -23 69 -24 99 -3 20 14 22 14 22 0
0 -13 12 -16 60 -16 l60 0 0 235 0 235 -60 0 -60 0 0 -56z m0 -219 c0 -104 -2
-125 -15 -125 -12 0 -15 20 -15 118 0 65 3 122 7 125 18 19 23 -4 23 -118z"/>
<path d="M6490 1185 l0 -235 54 0 c40 0 55 4 60 16 5 14 8 14 28 0 33 -23 75
-20 103 9 24 23 25 29 25 167 0 110 -3 148 -15 165 -21 29 -60 37 -97 18 -17
-8 -33 -15 -35 -15 -1 0 -3 25 -3 55 l0 55 -60 0 -60 0 0 -235z m150 -40 c0
-104 -2 -125 -15 -125 -12 0 -15 20 -15 118 0 65 3 122 7 125 18 19 23 -4 23
-118z"/>
<path d="M7100 1185 l0 -235 54 0 c40 0 55 4 60 16 5 14 8 14 28 0 33 -23 75
-20 103 9 24 23 25 29 25 167 0 110 -3 148 -15 165 -21 29 -60 37 -97 18 -17
-8 -33 -15 -35 -15 -1 0 -3 25 -3 55 l0 55 -60 0 -60 0 0 -235z m150 -40 c0
-104 -2 -125 -15 -125 -12 0 -15 20 -15 118 0 65 3 122 7 125 18 19 23 -4 23
-118z"/>
<path d="M7720 1185 l0 -235 60 0 60 0 0 77 c0 42 3 73 8 69 4 -4 16 -37 27
-74 l21 -67 46 -3 47 -3 -10 38 c-6 21 -22 74 -35 117 l-25 79 31 65 c40 85
39 92 -11 92 -39 0 -41 -2 -61 -50 -11 -27 -25 -50 -29 -50 -5 0 -9 41 -9 90
l0 90 -60 0 -60 0 0 -235z"/>
<path d="M8520 1385 l0 -35 61 0 c49 0 60 -3 56 -14 -3 -8 -18 -72 -32 -143
-15 -70 -33 -154 -40 -185 l-13 -58 59 0 c53 0 59 2 64 23 33 141 75 367 75
402 l0 45 -115 0 -115 0 0 -35z"/>
<path d="M9090 1305 l0 -115 59 0 c51 0 59 3 64 21 3 12 10 18 16 14 17 -10
15 -218 -1 -213 -8 3 -14 27 -16 62 l-3 57 -57 -3 -57 -3 1 -46 c1 -101 50
-145 151 -137 42 4 60 11 81 32 26 26 27 29 27 150 0 105 -3 127 -18 144 -25
28 -72 35 -108 17 l-29 -16 0 41 0 40 75 0 75 0 0 35 0 35 -130 0 -130 0 0
-115z"/>
<path d="M10960 1390 l0 -30 60 0 60 0 0 30 0 30 -60 0 -60 0 0 -30z"/>
<path d="M11120 1185 l0 -235 60 0 60 0 0 235 0 235 -60 0 -60 0 0 -235z"/>
<path d="M4030 1145 l0 -195 60 0 59 0 3 157 c2 114 6 157 16 161 9 3 12 -33
12 -157 l0 -161 60 0 59 0 3 157 c2 114 6 157 16 161 9 3 12 -33 12 -157 l0
-161 61 0 60 0 -3 176 c-3 173 -3 176 -28 196 -28 22 -72 19 -102 -9 -17 -15
-19 -15 -38 2 -12 11 -36 19 -55 19 -19 0 -43 -8 -55 -19 -20 -18 -20 -18 -20
3 0 20 -5 22 -60 22 l-60 0 0 -195z"/>
<path d="M4574 1330 c-45 -14 -73 -50 -80 -103 l-7 -47 57 0 56 0 0 45 c0 33
4 45 15 45 11 0 15 -12 15 -45 l0 -45 -64 -29 c-59 -26 -65 -33 -71 -65 -7
-37 -1 -91 13 -114 15 -23 80 -27 102 -7 18 16 20 16 20 2 0 -14 11 -17 60
-17 l60 0 0 145 c0 197 -13 228 -106 239 -22 3 -53 1 -70 -4z m56 -260 c0 -38
-4 -50 -15 -50 -19 0 -21 70 -3 88 18 18 18 17 18 -38z"/>
<path d="M5100 1145 l0 -195 60 0 59 0 3 157 c2 114 6 157 16 161 9 3 12 -33
12 -157 l0 -161 60 0 59 0 3 157 c2 114 6 157 16 161 9 3 12 -33 12 -157 l0
-161 60 0 60 0 0 164 c0 90 -3 172 -6 181 -9 23 -61 47 -87 39 -12 -3 -30 -13
-39 -22 -16 -14 -20 -13 -38 3 -12 11 -36 19 -55 19 -19 0 -43 -8 -55 -19 -20
-18 -20 -18 -20 3 0 20 -5 22 -60 22 l-60 0 0 -195z"/>
<path d="M5562 1164 c3 -171 4 -176 27 -195 28 -23 69 -24 99 -3 20 14 22 14
22 0 0 -13 12 -16 60 -16 l60 0 0 195 0 195 -60 0 -60 0 0 -160 c0 -136 -2
-160 -15 -160 -13 0 -15 24 -15 160 l0 160 -61 0 -61 0 4 -176z"/>
<path d="M6269 1333 c-41 -7 -78 -52 -85 -106 l-7 -47 56 0 56 0 3 42 c4 59
28 63 28 5 l0 -45 -64 -30 c-58 -27 -65 -34 -72 -66 -6 -37 0 -91 14 -114 15
-23 80 -27 102 -7 18 16 20 16 20 2 0 -14 11 -17 60 -17 l60 0 -1 148 c-1 81
-4 158 -8 171 -13 48 -88 77 -162 64z m51 -263 c0 -38 -4 -50 -15 -50 -19 0
-21 70 -3 88 18 18 18 17 18 -38z"/>
<path d="M6794 1325 c-3 -8 -4 -87 -2 -175 3 -156 4 -162 27 -181 28 -23 69
-24 99 -3 20 14 22 14 22 0 0 -13 12 -16 60 -16 l60 0 0 195 0 195 -60 0 -60
0 0 -160 c0 -136 -2 -160 -15 -160 -13 0 -15 24 -15 160 l0 160 -55 0 c-39 0
-57 -4 -61 -15z"/>
<path d="M7486 1326 c-50 -19 -73 -54 -75 -118 -1 -27 0 -28 54 -28 l55 0 0
45 c0 34 4 45 16 45 12 0 14 -10 12 -47 -3 -47 -3 -47 -58 -69 -66 -26 -82
-51 -77 -118 3 -36 11 -55 27 -68 28 -22 68 -23 92 -1 17 15 18 15 18 0 0 -14
11 -17 60 -17 l60 0 -1 148 c-1 81 -4 158 -8 171 -15 55 -103 83 -175 57z m64
-256 c0 -38 -4 -50 -15 -50 -19 0 -21 70 -3 88 18 18 18 17 18 -38z"/>
<path d="M8094 1330 c-45 -14 -73 -50 -80 -103 l-7 -47 57 0 56 0 0 45 c0 33
4 45 15 45 11 0 15 -12 15 -45 l0 -45 -64 -29 c-59 -26 -65 -33 -71 -65 -7
-37 -1 -91 13 -114 15 -23 80 -27 102 -7 18 16 20 16 20 2 0 -14 11 -17 60
-17 l60 0 -1 148 c-1 81 -4 158 -8 171 -14 51 -99 81 -167 61z m56 -260 c0
-38 -4 -50 -15 -50 -19 0 -21 70 -3 88 18 18 18 17 18 -38z"/>
<path d="M8320 1145 l0 -195 60 0 60 0 0 108 c0 93 3 111 18 125 10 10 26 17
35 17 14 0 17 11 17 71 0 75 -2 77 -47 36 l-23 -20 0 26 c0 26 -2 27 -60 27
l-60 0 0 -195z"/>
<path d="M9938 1333 c-31 -8 -48 -65 -48 -162 0 -155 35 -196 133 -156 14 6
17 2 17 -29 0 -29 -4 -36 -20 -36 -11 0 -20 7 -20 15 0 13 -11 16 -51 14 -27
-2 -52 -5 -54 -8 -2 -4 3 -21 10 -39 18 -41 53 -55 134 -50 57 4 67 8 92 36
l29 32 0 195 0 195 -55 0 c-42 0 -55 -3 -55 -15 0 -8 -2 -15 -4 -15 -2 0 -20
7 -39 15 -37 15 -40 16 -69 8z m102 -163 c0 -82 -3 -100 -15 -100 -12 0 -15
17 -15 93 0 52 3 97 7 100 18 19 23 -1 23 -93z"/>
<path d="M10190 1145 l0 -195 60 0 59 0 3 157 c2 114 6 157 16 161 9 3 12 -33
12 -157 l0 -161 60 0 59 0 3 157 c2 114 6 157 16 161 9 3 12 -33 12 -157 l0
-161 61 0 60 0 -3 176 c-3 173 -3 176 -28 196 -28 22 -72 19 -102 -9 -17 -15
-19 -15 -38 2 -12 11 -36 19 -55 19 -19 0 -43 -8 -55 -19 -20 -18 -20 -18 -20
3 0 20 -5 22 -60 22 l-60 0 0 -195z"/>
<path d="M10734 1330 c-45 -14 -73 -50 -80 -103 l-7 -47 57 0 56 0 0 45 c0 33
4 45 15 45 11 0 15 -12 15 -45 l0 -45 -64 -29 c-59 -26 -65 -33 -71 -65 -7
-37 -1 -91 13 -114 15 -23 80 -27 102 -7 18 16 20 16 20 2 0 -14 11 -17 60
-17 l60 0 -1 148 c-1 81 -4 158 -8 171 -14 51 -99 81 -167 61z m56 -260 c0
-38 -4 -50 -15 -50 -19 0 -21 70 -3 88 18 18 18 17 18 -38z"/>
<path d="M10960 1145 l0 -195 60 0 60 0 0 195 0 195 -60 0 -60 0 0 -195z"/>
<path d="M11474 1329 c-56 -16 -76 -50 -82 -133 -7 -105 5 -181 32 -213 18
-21 34 -27 78 -31 95 -8 148 40 148 133 l0 35 -50 0 -50 0 0 -51 c0 -43 -3
-50 -17 -47 -16 3 -18 17 -18 122 0 87 3 120 13 124 8 2 12 -9 12 -37 l0 -41
56 0 57 0 -6 41 c-6 47 -29 76 -74 95 -36 16 -54 16 -99 3z"/>
<path d="M11774 1330 c-16 -5 -41 -21 -54 -35 -21 -23 -24 -36 -27 -138 -4
-94 -1 -118 14 -147 22 -43 54 -60 116 -60 62 0 105 23 123 65 18 43 19 220 1
254 -27 53 -106 81 -173 61z m66 -185 c0 -104 -2 -125 -15 -125 -12 0 -15 20
-15 118 0 65 3 122 7 125 18 19 23 -4 23 -118z"/>
<path d="M11997 1333 c-4 -3 -7 -91 -7 -195 l0 -188 60 0 59 0 3 157 c2 114 6
157 16 161 9 3 12 -33 12 -157 l0 -161 60 0 59 0 3 157 c2 114 6 157 16 161 9
3 12 -33 12 -157 l0 -161 61 0 60 0 -3 175 c-3 149 -5 177 -20 192 -23 23 -82
22 -108 -2 -19 -18 -21 -18 -40 0 -12 11 -36 19 -55 19 -19 0 -43 -8 -55 -19
-20 -18 -20 -18 -20 3 0 20 -5 22 -53 22 -30 0 -57 -3 -60 -7z"/>
<path d="M2247 1243 c-4 -3 -7 -16 -6 -27 0 -19 1 -19 10 3 10 24 8 36 -4 24z"/>
<path d="M11280 1001 l0 -51 46 0 45 0 -3 48 -3 47 -42 3 -43 3 0 -50z"/>
<path d="M11660 950 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
